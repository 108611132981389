import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    currentGroup: '',
    currentPage: '',
    preventScroll: false,
    openModal: '',
    exitHref: '',
    returnFocusTo: '',
    preventPlay: true,
    playingVideo: '',
    textZoom: false,
  });

  const updateGlobalState = () => {
    setGlobalState({ ...globalState });
  };

  return (
    <GlobalContext.Provider value={{ globalState, updateGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
